import React from 'react'
import { Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'next-i18next'
import { CONCERT_COLOURS } from '../constants/concertTypes'

const useConcertStyles = makeStyles(() => {
  return Object.fromEntries(
    Object.entries(CONCERT_COLOURS).map(([type, color]) => [
      type,
      {
        color: color,
        borderColor: color,
        '&.selected': {
          color: 'white',
          border: '2px solid',
          borderColor: color,
          backgroundColor: color
        }
      }
    ])
  )
})

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  chip: {
    padding: '5px',
    fontSize: theme.typography.pxToRem(16),
    textTransform: 'capitalize',
    borderWidth: '2px',
    marginRight: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
  },
  '@media screen and (max-width: 900px)': {
    chip: {
      padding: 0,
      fontSize: theme.typography.pxToRem(12),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      height: '24px'
    }
  }
}))

type RefinementListConcertsProps = {
  refine: (type: string) => void
  refinedItems: string[]
}

const RefinementListConcerts: React.FC<RefinementListConcertsProps> = ({
  refine,
  refinedItems
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const concertClasses = useConcertStyles()

  const order = [
    'symphonic',
    'opera',
    'ballet',
    'church',
    'chamber',
    'soloduo',
    'song',
    'churchmusic',
    'earlymusic',
    'avantgarde',
    'crossover',
    'youth',
    'student'
  ]

  return (
    <>
      {order.map((item) => (
        <Chip
          className={`${concertClasses[item]} ${classes.chip} ${
            refinedItems &&
            refinedItems.some((refinement) => refinement === item)
              ? 'selected'
              : ''
          }`}
          key={item}
          clickable
          label={t(item)}
          variant={
            refinedItems &&
            refinedItems.some((refinement) => refinement === item)
              ? 'default'
              : 'outlined'
          }
          onClick={() => refine(item)}
        />
      ))}
    </>
  )
}

export default RefinementListConcerts
