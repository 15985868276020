import { useMemo } from 'react'

type CurrentDateProps = {
  withTime: boolean
}

export const useCurrentDate = (props?: CurrentDateProps): Date =>
  useMemo(() => {
    const { withTime = true } = props ?? {}

    const date = new Date()

    if (!withTime) {
      date.setHours(0)
      date.setMinutes(0)
    }

    return date
  }, [])
