import React from 'react'
import { Popover, Button, ButtonGroup } from '@material-ui/core'
import {
  Close as CloseIcon,
  MusicNote as MusicNoteIcon
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import {
  usePopupState,
  bindTrigger,
  bindPopover
} from 'material-ui-popup-state/hooks'
import { useTranslation } from 'next-i18next'
import RefinementListConcerts from './RefinementListConcerts'

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    boxShadow: '0px 3px 11px #08135914',
    borderRadius: '8px',
    width: '100%',
    border: '2px solid white',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  button: {
    maxWidth: '250px',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white'
    },
    padding: theme.spacing(1, 5),
    borderRadius: '8px',
    boxShadow: '0 3px 11px #08135914',
    fontWeight: 'bold'
  },
  filterButton: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    fontWeight: 'bold',
    boxShadow: 'none',
    borderRadius: '8px',
    fontSize: '0.75rem'
  },
  textButton: {
    width: '100%'
  },
  expandedButtonGroup: {
    border: `2px solid ${theme.palette.primary.main}`
  },
  expandedButton: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      boxShadow: 'none'
    }
  },
  collapsedButton: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'white',
      color: theme.palette.primary.main,
      boxShadow: 'none'
    }
  },
  active: {
    border: `2px solid ${theme.palette.primary.main}`
  },
  popover: {
    maxWidth: 1000,
    marginTop: theme.spacing(2),
    borderWidth: 2,
    borderColor: theme.palette.primary.main
  },
  paper: {
    color: 'white',
    boxShadow: '0px 3px 11px #08135914',
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    borderWidth: 2,
    borderRadius: '8px',
    minWidth: '320px',
    padding: theme.spacing(3)
  },
  selector: {
    backgroundColor: 'white',
    borderRadius: 6,
    padding: 16
  },
  elevation: {
    boxShadow: '0px 3px 11px #08135914'
  },
  '@media screen and (min-width: 600px)': {
    filterButton: {
      fontSize: '0.875rem',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  }
}))

type TypePopoverProps = {
  types: string[]
  setTypes: (newTypes: string[]) => void
}

const TypePopover: React.FC<TypePopoverProps> = ({ types, setTypes }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'filterTypes'
  })

  const isActive = types.length > 0

  const toggleType = (type: string) =>
    setTypes(
      types.includes(type) ? types.filter((t) => t !== type) : [...types, type]
    )

  return (
    <>
      <ButtonGroup
        className={`${classes.buttonGroup} ${
          popupState.isOpen ? classes.expandedButton : classes.collapsedButton
        } ${popupState.isOpen && classes.expandedButtonGroup} ${
          isActive && classes.active
        }`}
        {...bindTrigger(popupState)}
      >
        <Button
          variant='contained'
          className={`${classes.filterButton} ${classes.textButton} ${
            popupState.isOpen ? classes.expandedButton : classes.collapsedButton
          }`}
        >
          {t('Types')}
        </Button>
        <Button
          variant='contained'
          className={`${classes.filterButton} ${
            popupState.isOpen ? classes.expandedButton : classes.collapsedButton
          }`}
        >
          {isActive ? (
            <CloseIcon onClick={() => setTypes([])} />
          ) : (
            <MusicNoteIcon />
          )}
        </Button>
      </ButtonGroup>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        className={classes.popover}
        PaperProps={{
          className: classes.paper
        }}
        elevation={0}
      >
        <div className={classes.selector}>
          <RefinementListConcerts refinedItems={types} refine={toggleType} />
        </div>
      </Popover>
    </>
  )
}

export default TypePopover
