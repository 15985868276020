import { ExtendedFirestoreInstance } from 'react-redux-firebase'
import useSearch from '../../hooks/useSearch'
import { Concert } from '../../types/types'
import { generateConcertSlug } from '../slugs'
import { getAllDocuments, Indexed } from '../meilisearch'

export const useConcertSearch = (
  filters: string,
  query: string,
  numHits?: number
) => useSearch<Concert>(filters, 'concerts', query, numHits)

export const generateConcertId = (
  firestore: ExtendedFirestoreInstance
): string => firestore.collection('concerts').doc().id

export const createConcert = async (
  firestore: ExtendedFirestoreInstance,
  data: Omit<Concert, 'createdAt' | 'updatedAt' | 'isDeleted'>,
  concertId: string
): Promise<void> => {
  const slug = generateConcertSlug(data.dateTime, data.title)
  await firestore
    .collection('concerts')
    .doc(concertId)
    .set({
      ...data,
      slug,
      isDeleted: false,
      createdAt: firestore.FieldValue.serverTimestamp(),
      updatedAt: firestore.FieldValue.serverTimestamp()
    })
}

export const getConcertDoc = async (
  firestore: ExtendedFirestoreInstance,
  concertId: string
): Promise<firebase.firestore.DocumentSnapshot<Concert>> => {
  const concertDoc = await firestore.collection('concerts').doc(concertId).get()
  return concertDoc as firebase.firestore.DocumentSnapshot<Concert>
}

export const updateConcert = async (
  firestore: ExtendedFirestoreInstance,
  data: Partial<Concert>,
  concertId: string
): Promise<void> => {
  await firestore
    .collection('concerts')
    .doc(concertId)
    .update({
      ...data,
      isDeleted: false,
      updatedAt: firestore.FieldValue.serverTimestamp()
    })
}

export const deleteConcert = async (
  firestore: ExtendedFirestoreInstance,
  concertId: string
): Promise<void> => {
  await firestore.collection('concerts').doc(concertId).update({
    isDeleted: true
  })
}

export const getAllPublicConcerts = async () => {
  const concerts = await getAllDocuments<
    Indexed<Concert> & { countryCode: string }
  >('concerts')

  return concerts.filter((concert) => concert.isDeleted === false)
}
