import React from 'react'
import { Grid, Hidden, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SearchBox from '../components/SearchBox'
import RefinementListConcerts from '../components/RefinementListConcerts'

import LocationPopover, { SearchLocation } from '../components/LocationPopover'
import DatePopover from '../components/DatePopover'
import { useTranslation } from 'next-i18next'
import TypePopover from './ConcertTypePopover'
import RegionPopover from './RegionPopover'

const useStyles = makeStyles((theme) => ({
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    margin: theme.spacing(3, 0, 2, 0)
  },
  smallScreens: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  largeScreens: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  }
}))

type ConcertSearchBarProps = {
  showRegion?: boolean
  query?: string
  setQuery: (newQuery?: string) => void
  types?: string[]
  setTypes?: (newTypes?: string[]) => void
  date?: Date
  setDate: (newDate?: Date) => void
  location?: SearchLocation
  setLocation?: (newLocation?: SearchLocation) => void
}

const ConcertSearchBar: React.VFC<ConcertSearchBarProps> = ({
  showRegion = true,
  query,
  setQuery,
  types = [],
  setTypes,
  date,
  setDate,
  location,
  setLocation
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const toggleType = (type: string) => {
    const newTypes = types.includes(type)
      ? types.filter((t) => t !== type)
      : [...types, type]
    setTypes(newTypes)
  }

  return (
    <>
      {/* SMALL SCREENS */}
      <div className={classes.smallScreens}>
        <Grid container spacing={2} style={{ marginBottom: 2 }}>
          {showRegion && (
            <Grid item>
              <RegionPopover />
            </Grid>
          )}
          {setLocation && (
            <Grid item xs>
              <LocationPopover location={location} setLocation={setLocation} />
            </Grid>
          )}
          <Grid item xs>
            <DatePopover date={date} setDate={setDate} />
          </Grid>
          {setTypes && (
            <Grid item xs={false} sm>
              <TypePopover types={types} setTypes={setTypes} />
            </Grid>
          )}
          <Grid item xs sm={false}>
            <SearchBox query={query ?? ''} setQuery={setQuery} />
          </Grid>
        </Grid>
        <Hidden smDown>
          <div>
            <SearchBox query={query ?? ''} setQuery={setQuery} />
          </div>
        </Hidden>
      </div>

      {/* LARGER SCREENS */}
      <div className={classes.largeScreens}>
        <Grid spacing={2} container direction='row' justifyContent='flex-start'>
          {showRegion && (
            <Grid item>
              <RegionPopover />
            </Grid>
          )}
          {setLocation && (
            <Grid item>
              <LocationPopover location={location} setLocation={setLocation} />
            </Grid>
          )}
          <Grid item>
            <DatePopover date={date} setDate={setDate} />
          </Grid>
          <Grid item xs>
            <SearchBox query={query ?? ''} setQuery={setQuery} />
          </Grid>
        </Grid>
        {setTypes && (
          <div>
            <Typography variant='h3' className={classes.secondaryHeading}>
              {t('Concert Type')}
            </Typography>
            <RefinementListConcerts
              refinedItems={types ?? []}
              refine={toggleType}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default ConcertSearchBar
